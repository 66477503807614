<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog.show"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{dialog.text.title}}
        </v-card-title>
        <v-card-text>
            {{dialog.text.text}}</v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-1"
            :loading="actions.loading"
            :disabled="actions.loading"
            text
            @click="dialog.show = false"
          >
            {{dialog.text.cancel}}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            :loading="actions.loading"
            :disabled="actions.loading"
            text
            @click="$emit('confirm'); dialog.show=false"
          >
            {{dialog.text.yes}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
    props:{
        actions:{
            type:Object, 
            required:true, 
            default:()=>({
                loading:false,
            })
        },
        dialog:{
            type:Object,
            required:true,
            default:()=>({
                show:false,
            })
        }
    }
}
</script>

<style>

</style>